import React from 'react'
import { useForm } from "react-hook-form"
import styled from 'styled-components'

const StyledMutuiForm = styled.div`
    max-width: 500px;
    width: 100%;
    background: #fff;
    padding: 20px;
    position: absolute;
    top: 50px;
    right: 0;

    .disclaimer {
        line-height: 15px;
        font-size: 12px !important;
    }

    input, select {
        background-color: #fff;
        border-width: 0 0 1px 0;
        border-color: #666666;
        font-size: 14px;
        border-radius: 0;
    }
`

const FormMutui = ({className}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            Motivo: '',
            ImportoTotale: '',
            Importo_del_Mutuo_Richiesto: '',
            Tasso: '',
            Stato_ricerca_dell_immobile: '',
            AnniMutuo: ''
        }
    })

    const onSubmit = (data) => {
        const params = new URLSearchParams(data).toString();
        window.location.href = `${process.env.LANDING_MUTUI_ENDPOINT}?${params}`;
    }

    return (
        <StyledMutuiForm className={`form-mutui ${className}`}>
            <h3 className='text-center fw-bold'>Configura il tuo Mutuo!</h3>
            <p className='text-center disclaimer mb-3 py-2'>
                Inserisci i dati relativi al mutuo. Scegli tasso e durata del finanziamento.
                Puoi sempre tornare indietro e modificare i dati inseriti.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <select {...register("Motivo", { required: true })} className='form-select'>
                    <option value="" disabled>Finalità del Mutuo *</option>
                    <option value="Acquisto immobile in classe energetica A o B">Acquisto immobile in classe energetica A o B</option>
                    <option value="Acquisto prima casa">Acquisto prima casa</option>
                    <option value="Acquisto seconda casa">Acquisto seconda casa</option>
                    <option value="Ristrutturazione">Ristrutturazione</option>
                    <option value="Surroga">Surroga</option>
                </select>

                <input type="number" className='form-control' {...register("ImportoTotale", { required: true, valueAsNumber: true })} placeholder="Valore dell'Immobile *" required />
                <div className="input-group has-validation">
                    <input type="number" className={`form-control ${errors.Importo_del_Mutuo_Richiesto ? 'is-invalid' : '' }`} {...register("Importo_del_Mutuo_Richiesto", { required: true, valueAsNumber: true, min: 50000 })} placeholder="Importo richiesto*" />
                    <div className="invalid-feedback">
                        L'importo minimo consentito è di 50.000&euro;
                    </div>
                </div>
                <select {...register('Tasso', { required: true })} >
                    <option value="" disabled>Tipo di Tasso *</option>
                    <option value="Tasso Fisso">Tasso Fisso</option>
                    {/* <option value="Tasso Variabile">Tasso Variabile</option> */}
                </select>
                <select {...register('Stato_ricerca_dell_immobile', { required: true })} className='form-select'>
                    <option value="" disabled>Stato Ricerca Immobile *</option>
                    <option value="Firmato compromesso">Firmato compromesso</option>
                    <option value="Fatta proposta di acquisto">Fatta proposta di acquisto</option>
                    <option value="Individuato immobile">Individuato immobile</option>
                    <option value="Alla ricerca dell'immobile">Alla ricerca dell'immobile</option>
                    <option value="Immobile già di proprietà">Immobile già di proprietà</option>
                </select>
                <select {...register('AnniMutuo')} className='form-select'>
                    <option value="" disabled>Durata Finanziamento in anni *</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                </select>

                <button type='submit' className='forward w-100 mx-0 mt-4'>AVANTI</button>
            </form>
        </StyledMutuiForm>
    )
}

export default FormMutui