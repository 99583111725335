import React, { useState } from "react";
import styled from "styled-components";
/* import { StaticImage } from "gatsby-plugin-image"; */
import { Link } from "gatsby";

const StyledFooterFinal = styled.div`
  background-color: #34495e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  color: #fff;
  padding: 3em;

  @media(max-width: 768px) {
    flex-direction: column;
  }

  .item {
    display: flex;
    flex-direction: column;
    &__menu {
      list-style-type: none;
      line-height: 2em;
      margin-left: auto;
      a {
        color: #FFF464;
        text-decoration: none;
      }
    }
    .menu {
        &__link {
            display: inline;
            padding-right: 0.75em;
            &:not(:last-child):after {
                content: '|';
                padding-left: 0.75em;
                color: #FFF464;
            }
        }
    }
    &--horizontal {
      flex-direction: row;
      display: flex;
      align-items: center;
      @media(max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        padding: 0 1em;
        @media(max-width: 768px) {
          padding: 0;
        }
      }
    }
  }
`;

const FooterFinal = (props) => {
  const [currentYear] = useState(new Date().getFullYear());

  return (
    <StyledFooterFinal>
      <div className="item--horizontal">
        <a href="https://www.organismo-am.it/b/0/09334011005/08E5A03D-0A14-4169-AEC1-C273CFD2376A/g.html" target="_blank" rel="noreferrer">
          <img src="https://www.prestitifaidate.it/wp/wp-content/uploads/2023/09/logoOAM_A107_big2.png" className="mb-3 mb-lg-0" alt="OAM OPERATORE AUTORIZZATO AD OPERARE" loading="lazy" width="80" height="81" />
        </a>
        <div className="item">
          <p className="__title fw-bold">PrestitiFaidaTe.it è un marchio registrato di proprietà del Gruppo Santamaria S.p.A.</p>
          <p>
            Sede legale: Via Tortona, 33 - 20144 MILANO (MI)
          </p>
          <p>© {currentYear} Prestiti Fai da Te</p>
          <p id="disclaimer-prestiti">
            <small className="mb-2">* Per prestiti erogati attraverso la formula della cessione del quinto è possibile usufruire del tasso agevolato.</small>
          </p>
        </div>
      </div>
      <div className="item">
        <p>
          P.IVA 09334011005 Agente in Attività Finanziaria OAM n. A 107
        </p>
        <ul className="item__menu">
          <li className="menu__link">
            <a href="/cookie-policy/" onClick={() => window.location.href = '/cookie-policy/'}>Cookie</a>
          </li>
          <li className="menu__link">
            <a href="https://www.iusprivacy.eu/informativa-privacy-info-1005616530" target="_blank" rel="noreferrer">Privacy policy</a>
          </li>
          <li className="menu__link">
            <Link to="/trasparenza/">Trasparenza</Link>
          </li>
          <li className="menu__link">
            <a href="https://www.tlcws.com" target="_blank" rel="noreferrer">Credits</a>
          </li>
        </ul>
      </div>
    </StyledFooterFinal>
  )
}

export default FooterFinal;
