import React, { useEffect } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import FormMutui from "./FormMutui";

const StyledFixedFormContainer = styled.div`
  .sticky-form-button {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: #e7c700;
    color: #fff;
    font-size: 1.2rem;
    padding: 1rem 0;
    z-index: 10;

    &::after {
      content: url("/images/icons/freccia-in-giu-bianca.svg");
      position: absolute;
      top: -30px;
      background-color: #e7c700;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(180deg);
    }

    &.open {
      transition: padding, font-size 1s;
      padding: 0;
      font-size: 0;
      &::after {
        transition: transform 1s;
        transform: rotate(0deg);
      }
    }
  }

  .sticky-form-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 9;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
        margin-bottom: 100px;
    }
  }
`;

const handleClick = (e) => {
  document.querySelector('body').classList.toggle('overflow-hidden')
  const classes = document.querySelector("#sticky-form-container").classList

  document.querySelector('.sticky-form-button').classList.toggle('open')

  if (!classes.contains('animate__slideInUp') && !classes.contains('animate__slideOutDown')) {
    document.querySelector('#sticky-form-container').classList.toggle('animate__slideInUp')
  }
  else {
    document.querySelector('#sticky-form-container').classList.toggle('animate__slideInUp')
    document.querySelector('#sticky-form-container').classList.toggle('animate__slideOutDown')
  }
}



const FixedFormContainer = ({ mutui = false }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const stickyFormButton = document.querySelector('.sticky-form-button')

      if (!stickyFormButton.classList.contains('open')) {
        stickyFormButton.classList.toggle('animate__bounce')
      }
    }, 3000);


    return () => (clearInterval(interval))
  }, []);

  const form = mutui ? <FormMutui /> : (<form
    id="form-multi-step-mobile"
    action={process.env.GATSBY_FORM_ACTION}
    method="POST"
    className="form-multi-step"
  ></form>)

  return (
    <StyledFixedFormContainer>
      <div className="d-block d-lg-none">
        <div className="collapse" id="formCollapse">
          <div className="card card-body sticky-form-container animate__animated" id="sticky-form-container">
            <StaticImage
              src="../assets/images/logo_prestiti.png"
              className="img-fluid logo d-none d-md-block"
              alt="Logo prestiti"
              placeholder="blurred"
              layout="fixed"
              width={252}
              height={85}
            />
            {form}
          </div>
        </div>
        <button
          className="btn sticky-form-button animate__animated"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#formCollapse"
          aria-expanded="false"
          aria-controls="formCollapse"
          onClick={handleClick}
        >
          Richiedi <span className="fw-bold">subito</span> il tuo{" "}
          <span className="fw-bold">{mutui ? 'mutuo' : 'prestito'} online</span>
        </button>
      </div>
    </StyledFixedFormContainer>
  );
}


export default FixedFormContainer;
