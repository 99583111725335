import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const StyledNav = styled.nav`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  .container-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }

  &.navbar-yellow {
    background-color: #e7c700;
    min-height: 90px;

    @media (min-width: 992px) {
      min-height: 56px;
    }
  }

  .navbar-brand {
    position: absolute;
    top: -5px;
    left: 10vw;
    z-index: 1;
  }

  #topNavBarMenu {
    @media (min-width: 1400px) {
      display: block;
    }
  }

  .not-available {
    padding: 0 1em;
    font-size: 12px;
    position: absolute;
    right: 0;
    width: 100%;
    top: 20px;
    @media (min-width: 992px) {
      max-width: 62vw;
    }
  }
`;

class TopNavbar extends Component {
  render() {
    return (
      <StyledNav className="navbar navbar-expand-xxl d-none d-lg-block navbar-light navbar-yellow mb-5">
        <div className="container-1440">
          <Link className="navbar-brand d-none d-lg-block" to="/">
            <StaticImage
              src="../assets/images/logo_prestiti.png"
              className="img-fluid bg-white"
              alt="Logo prestiti"
              placeholder="blurred"
              layout="fixed"
              width={252}
              height={85}
            />
          </Link>
          {/* <div className="text-center ms-auto not-available">
            Limitazioni nell’erogazione dei servizi di PrestitiFaidaTe.it – Al momento i prodotti di Cessione del Quinto e di Prestito Personale non sono disponibili.
          </div> */}
          <div className="collapse navbar-collapse d-none" id="topNavBarMenu">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2">
                <Link className="nav-link" to="/finanziamento-auto/">
                  Finanziamento auto
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link" to="/finanziamento-moto/">
                  Finanziamento moto
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link" to="/prestiti-personali-veloci/">
                  Prestiti veloci
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className="nav-link"
                  to="/finanziamento-ristrutturazione-casa/"
                >
                  Prestito ristrutturazione casa
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className="nav-link"
                  to="/prestiti-personali-fino-a-50000-euro/"
                >
                  Prestito 50000€
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </StyledNav>
    );
  }
}

export default TopNavbar;
