import React, { Component } from "react";
import Prefooter from "./Prefooter";
import Convenzioni from "./Convenzioni";
import FooterFinal from "./FooterFinal";

class Footer extends Component {
  render() {
    return (
      <footer>
        <Prefooter />
        <Convenzioni />
        <FooterFinal />
      </footer>
    );
  }
}

export default Footer;
