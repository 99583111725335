import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledConvenzioni = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 3em 3em;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .item {
    &__title {
      color: #666666;
    }
  }

  .convenzioni {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    &__logo {
      margin: 1em;
    }
  }
`;

const Convenzioni = (props) => (
  <StyledConvenzioni>
    <div className="item">
      <h2 className="item__title">Le convenzioni* sul territorio:</h2>
      <DefaultButton
        title="Scorprile tutte"
        url="/convenzioni-nazionali-e-regionali/"
        className="mt-2 btn-outline-dark"
      />
    </div>
    <div>
      <div className="convenzioni">
        <StaticImage
          src="../assets/images/convenzioni/carabinieri.png"
          className="img-fluid convenzioni__logo"
          alt="Carabinieri"
          placeholder="blurred"
          layout="fixed"
          width={139}
          height={139}
          objectFit="contain"
        />
        <StaticImage
          src="../assets/images/convenzioni/inps.png"
          className="img-fluid convenzioni__logo"
          alt="INPS"
          placeholder="blurred"
          layout="fixed"
          width={54}
          height={77}
          objectFit="contain"
        />
        <StaticImage
          src="../assets/images/convenzioni/poste.png"
          className="img-fluid convenzioni__logo"
          alt="Poste Italiane"
          placeholder="blurred"
          layout="fixed"
          width={146}
          height={41}
          objectFit="contain"
        />
        <StaticImage
          src="../assets/images/convenzioni/enasarco.png"
          className="img-fluid convenzioni__logo"
          alt="Fondazione Enasarco"
          placeholder="blurred"
          layout="fixed"
          width={161}
          height={112}
          objectFit="contain"
        />
        <StaticImage
          src="../assets/images/convenzioni/ministero.png"
          className="img-fluid convenzioni__logo"
          alt="Ministero"
          placeholder="blurred"
          layout="fixed"
          width={145}
          height={50}
          objectFit="contain"
        />
      </div>
    </div>
  </StyledConvenzioni>
);

export default Convenzioni;
