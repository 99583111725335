import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledButton = styled.button`
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: ${(props) => props.bgColor};

  .btn {
    &__arrow {
      margin-left: 1em;
    }
  }
`;

const DefaultButton = (props) => {
  if (props.external) {
    return (
      <a href={props.url ? props.url : '#'} style={{ textDecoration: 'none' }}>
        <StyledButton
          className={`btn text-uppercase px-5 py-2 ${props.className}`}
          bgColor={props.bgColor}
        >
          {props.title}
          {props.arrow && (
            <StaticImage
              src="../../assets/images/icons/right-arrow-long.svg"
              className="img-fluid d-inline-block btn__arrow"
              alt="Arrow"
              placeholder="blurred"
              layout="fixed"
              width={38}
              height={8}
            />
          )}
        </StyledButton>
      </a>
    )
  }
  else {
    return (
      <Link to={props.url ? props.url : '#'} style={{ textDecoration: 'none' }}>
        <StyledButton
          className={`btn text-uppercase px-5 py-2 ${props.className}`}
          bgColor={props.bgColor}
        >
          {props.title}
          {props.arrow && (
            <StaticImage
              src="../../assets/images/icons/right-arrow-long.svg"
              className="img-fluid d-inline-block btn__arrow"
              alt="Arrow"
              placeholder="blurred"
              layout="fixed"
              width={38}
              height={8}
            />
          )}
        </StyledButton>
      </Link>
    )
  } 
}

export default DefaultButton;
