import React, { Component } from 'react'
import TopNavbar from '../components/TopNavbar'
import PrimaryMenu from '../components/PrimaryMenu'

class Header extends Component {
    render() {
        return (
            <header>
                <TopNavbar />
                <PrimaryMenu />
            </header>
        )
    }
}

export default Header