import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const StyledTargetFullBanner = styled.div`
  /* background-color: ${(props) => (props.bgColor ? props.bgColor : "#f2d001")}; */
  --color-one:#FAB333;
  --color-two:#E8C702;
  --color-three:#25A298;
  --color-four:#FEE56E;
  --gradient_animation: 25s linear 0s infinite gradient_animation;
  --gradient_background_short:-webkit-linear-gradient(-45deg, var(--color-one) 0%, var(--color-two) 25%, var(--color-four) 95%) 0 0/600%;
  background: var(--gradient_background_short);
  animation: 25s linear 0s infinite gradient_animation;

  .title h2 {
    display: inline-block;
    color: ${props => props.color ? props.color : '#000'};
    margin-right: 0.5em;
  }

  .arrow {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    display: inline-block;
  }

  .mobile-break {
    @media(max-width: 576px) {
      display: block;
    }
  }

  @-webkit-keyframes gradient_animation {
    0% {
     background-position:0 0
    }
    50% {
     background-position:100% 0
    }
    100% {
     background-position:0 0
    }
   }
   @keyframes gradient_animation {
    0% {
     background-position:0 0
    }
    50% {
     background-position:100% 0
    }
    100% {
     background-position:0 0
    }
   }
`;

const TargetFullBanner = (props) => (
  <StyledTargetFullBanner
    className={`py-5 text-center ${props.className}`}
    bgColor={props.bgColor}
    color={props.color}
  >
    <div className="title pb-1 fw-bold">
      <h2 dangerouslySetInnerHTML={{
        __html: props.title
      }}></h2>
      {props.arrow && (
        <StaticImage
          src="../assets/images/icons/slider-prev.svg"
          className="arrow d-none d-sm-inline-block"
          alt="Prev slide"
          placeholder="blurred"
          layout="fixed"
          width={18}
          height={30}
          onClick={props.onClick}
        />
      )}
    </div>

    <p className="description">{props.description}</p>
  </StyledTargetFullBanner>
);

export default TargetFullBanner;
