import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledAccordion = styled.div`
  width: 100vw;

  .bg-gray {
    background-color: #6d767b;
  }

  .bg-yellow {
    background-color: #e7c700;
  }

  .btn-link {
    &::after {
      display: none;
    }
  }

  .list-group-item {
    background-color: #f5f5f5;
  }
`;

const MobileMenu = (props) => {
  return (
    <StyledAccordion
      className="accordion accordion-flush collapse"
      id="accordionExample"
    >
      {props.pages.map((page, index) => {
        // If page is a parent and not "Chi siamo"
        if (!page.ancestors && page.title.toLowerCase() !== "chi siamo") {
          const parentPageTitle = page.title;

          return (
            <div className="accordion-item" key={uuidv4()}>
              <h2 className="accordion-header" id={`heading-${index}`}>
                <button
                  className="accordion-button shadow-none collapsed bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${index}`}
                >
                  <span className="fw-bold text-uppercase text-dark">
                    {page.title}
                  </span>
                </button>
              </h2>
              <div
                id={`collapse-${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading-${index}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-0">
                  <div className="list-group">
                    {props.pages.map((page) => {
                      if (
                        page.ancestors &&
                        page.ancestors.nodes[0].title === parentPageTitle
                      ) {
                        return (
                          <Link
                            to={`/${page.slug}/`}
                            className="list-group-item list-group-item-action py-3"
                            key={uuidv4()}
                          >
                            {page.title}
                          </Link>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return null;
      })}
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-vantaggi">
          <Link to="/vantaggi/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link bg-white text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-dark">
                I Vantaggi
              </span>
            </button>
          </Link>
        </h2>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-come-funziona">
          <Link to="/come-funziona/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link bg-white text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-dark">
                Come funziona
              </span>
            </button>
          </Link>
        </h2>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-faq">
          <Link to="/faq/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link bg-white text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-dark">FAQ</span>
            </button>
          </Link>
        </h2>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-chi-siamo">
          <Link to="/chi-siamo/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link btn-link--dark bg-gray text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-white">
                Chi siamo
              </span>
            </button>
          </Link>
        </h2>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-convenzioni">
          <Link to="/convenzioni-nazionali-e-regionali/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link btn-link--dark bg-gray text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-white">
                Convenzioni
              </span>
            </button>
          </Link>
        </h2>
      </div>
{/*       <div className="accordion-item">
        <h2 className="accordion-header" id="heading-contattaci">
          <Link to="/contattaci/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link btn-link--dark bg-gray text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-white">
                Contattaci
              </span>
            </button>
          </Link>
        </h2>
      </div> */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading-blog">
          <Link to="/blog/" className="text-decoration-none">
            <button
              className="accordion-button shadow-none collapsed btn-link bg-yellow text-decoration-none"
              type="button"
            >
              <span className="fw-bold text-uppercase text-dark">
                A Tutto Prestiti
              </span>
            </button>
          </Link>
        </h2>
      </div>
    </StyledAccordion>
  );
};

export default MobileMenu;
