import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import MenuFooter from "../sections/MenuFooter";
import Helmet from "react-helmet";
import TargetFullBanner from "../components/TargetFullBanner";
import "../assets/css/form.css";
import "../assets/css/custom.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components'
import FixedFormContainer from "../components/FixedFormContainer";
import { Link } from "gatsby";

const StyledLayout = styled.div`
  max-width: 1920px;
  margin: auto;
  overflow-x: hidden;
`

class Layout extends React.Component {
  render() {
    return (
      <StyledLayout>
        <FixedFormContainer mutui={this.props.mutui} />
        <Header />
        <Helmet
          title={
            this.props.helmetTitle || "PrestitiFaiDaTe"
          }
          defer={false}
          htmlAttributes={{ lang: "it" }}
        >
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5"></meta>
          <meta name="description" content={this.props.helmetDescription || "Prestiti Fai da Te: Esito in 1 Ora, Senza Costi di Gestione. Richiedere un prestito non è mai stato così facile."} />
        </Helmet>
        <main>{this.props.children}</main>
        <MenuFooter />
        <Link to="/faq/">
        <TargetFullBanner
          title="Vuoi saperne di più? <span class='mobile-break'></span><br /><button class='btn btn-secondary mt-2'>Leggi le nostre FAQ</button>"
          bgColor="#f1f1f1"
          color="#666"
          
          className="mt-2"
        />
        </Link>
        <Footer />
      </StyledLayout>
    );
  }
}

export default Layout;
