import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";
import { getDescription } from "../helpers";

const StyledMenuFooter = styled.div`
  background-color: #e7c700;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
  }

  .items {
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }

    &__block {
      padding: 0 2em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: 1024px) {
        flex: 50%;
      }
    }

    &__title {
      color: #34495e;
      font-weight: 500;
      font-size: 24px;
    }

    &__menu {
      list-style-image: url("/images/icons/arrow-right.svg");

      a {
        color: #000;
        text-decoration: none;
      }

      li {
        padding-left: 10px;
      }
    }
  }
`;

const MenuFooter = (props) => {

  return (
    <StaticQuery
      query={graphql`
        query PageQuery {
          allWpPage(
            sort: {fields: [date]}
            filter: {slug: {nin: ["chi-siamo", "consolidamento-debiti", "destinatari-prestiti-agevolati"]}}
          ) {
            nodes {
              title
              slug
              ancestors {
                nodes {
                  ... on WpPage {
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <StyledMenuFooter className={`mt-2 pb-5 ${props.className}`} {...props} id="section-footer-links">
          <StaticImage
            src="../assets/images/ingranaggio-separatore-light.png"
            className="img-fluid mb-5"
            alt="Separatore"
            placeholder="blurred"
            layout="fixed"
            width={138}
            height={48}
          />

          <h2 className="title fw-bold fs-1">Scegli il tuo prestito fai da te</h2>
          <div className="items">
            {data.allWpPage.nodes.map((page) => {
              if (!page.ancestors) {
                const parentPageTitle = page.title;

                return (
                  <div className="items__block" key={uuidv4()}>
                    <h3 className="pt-4 fw-bolder items__title">{page.title}</h3>
                    <p className="pt-4 fw-bolder items__description fw-bold mb-4">{getDescription(page.slug)}</p>
                    <ul className="items__menu">
                      {data.allWpPage.nodes.map((page) => {
                        if (
                          page.ancestors &&
                          page.ancestors.nodes[0].title === parentPageTitle
                        ) {
                          return (
                            <li key={uuidv4()}>
                              <Link to={`/${page.slug}/`}>{page.title}</Link>
                            </li>
                          );
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                );
              }

              return null;
            })}
          </div>
        </StyledMenuFooter>
      )}
    />
  );
};

export default MenuFooter;
