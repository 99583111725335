export const getDescription = (slug) => {
  switch (slug) {
    case "cessione-del-quinto":
      return "Comoda, Conveniente e Senza Pensieri.";
    case "prestiti-personali":
      return "Veloci, Convenienti e Sicuri";
    case "finanziamenti":
      return "Tante soluzioni su misura per le tue esigenze";
    case "mutui-casa":
      return "Con PrestitiFaidaTe.it scopri come è facile trovare il mutuo più adatto a te!";

    default:
      return "";
  }
};
