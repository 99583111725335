import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";
import DefaultButton from "./buttons/DefaultButton";
import { StaticImage } from "gatsby-plugin-image";
import MobileMenu from "./MobileMenu";
import { getDescription } from "../helpers";

const StyledNav = styled.nav`
  max-width: 1920px;
  margin: 0 auto;

  .container-1440 {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
  }

  .navbar-collapse {
    @media(max-width: 1600px) {
      justify-content: center;
    }
  }

  .nav-item {
    font-weight: 700;
    font-size: 0.9rem;
    &--yellow {
      background-color: #e7c700;
    }
    &--gray {
      background-color: #f1f1f1;
    }
  }

  .wp-menu:hover .dropdown {
    display: flex;
    position: absolute;
    z-index: 20;
    background: #f1f1f1;
  }

  .navbar-toggler {
    margin-right: 25px;
  }

  .dropdown {
    display: none;
    position: absolute;
    z-index: 2;
    background: #fff;
    left: 0;

    &__parent {
      padding: 8em;
      flex-basis: 45%;
    }

    &__children {
      border-left: 2px solid #4c7aa6;
      padding: 3em;
      margin: 5em 0;
      flex-basis: 55%;
    }

    &__title {
      color: #527191;
      font-weight: 600;
    }

    &__description {
      padding-left: 2em;
    }

    .arrow {
      display: inline-block;
      margin-right: 1em;
    }

    &__menu {
      list-style-type: none;
      line-height: 2em;
      margin-left: auto;
      a {
        color: #000;
        text-decoration: none;
      }
    }

    &__cta-block {
      padding: 3em;
      background-color: #527191;
      color: #fff;
      flex-basis: 25%;
      display: flex;
      flex-direction: column;

      &__item {
        margin: 2em 0;
      }

      h4 {
        font-weight: 500;
      }
    }
  }
`;
const PrimaryMenu = () => (
  <StaticQuery
    query={graphql`
      {
        allWpPage(sort: { fields: [menuOrder] }) {
          nodes {
            title
            slug
            ancestors {
              nodes {
                ... on WpPage {
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <StyledNav className="navbar navbar-expand-lg navbar-light bg-white pt-0 pb-0">
        <div className="container-1440 py-3 py-lg-0 d-flex">
          <Link className="navbar-brand d-block d-lg-none" to="/">
            <StaticImage
              src="../assets/images/logo_prestiti.png"
              className="img-fluid bg-white"
              alt="Logo prestiti"
              placeholder="blurred"
              layout="fixed"
              width={252 / 1.5}
              height={85 / 1.5}
            />
          </Link>
          <a href="tel:0699794910" className="ms-auto d-lg-none">
            <StaticImage
              src="../assets/images/icona-chiamaci-ora.jpg"
              className=""
              alt="0699794910"
              placeholder="blurred"
              layout="fixed"
              width={156 / 2}
              height={122 / 2}
              objectFit="contain"
            />
          </a>
          <button
            className="navbar-toggler ml-5 border-0 shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#accordionExample"
            aria-controls="accordionExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-xxl-auto mb-2 mb-lg-0 bg-gray">
              {data.allWpPage.nodes.map((page) => {
                // If page is a parent and not "Chi siamo"
                if (
                  !page.ancestors &&
                  page.title.toLowerCase() !== "chi siamo"
                ) {
                  const parentPageTitle = page.title;

                  return (
                    <li className="nav-item px-1 wp-menu" key={uuidv4()}>
                      <Link
                        className="nav-link py-3 px-2 px-xxl-4 text-uppercase text-center"
                        to={`/${page.slug}/`}
                      >
                        {page.title}
                      </Link>
                      {page.slug !== 'consolidamento-debiti' &&
                        <div className="dropdown">
                          <div className="dropdown__parent">
                            <h4 className="dropdown__title mb-4">
                              <StaticImage
                                src="../assets/images/icons/slider-next.svg"
                                className="arrow"
                                alt="Prev slide"
                                placeholder="blurred"
                                layout="fixed"
                                width={18}
                                height={30}
                                objectFit="contain"
                              />
                              {page.title}
                            </h4>
                            <p className="dropdown__description">
                              {getDescription(page.slug)}
                            </p>
                          </div>
                          <div className="dropdown__children">
                            <ul className="dropdown__menu">
                              {data.allWpPage.nodes.map((page) => {
                                if (
                                  page.ancestors &&
                                  page.ancestors.nodes[0].title ===
                                  parentPageTitle
                                ) {
                                  return (
                                    <li key={uuidv4()}>
                                      <Link to={`/${page.slug}/`}>
                                        {page.title}
                                      </Link>
                                    </li>
                                  );
                                }

                                return null;
                              })}
                            </ul>
                          </div>
                          <div className="dropdown__cta-block">
                            <div className="dropdown__cta-block__item">
                              <h4>Conosci i vantaggi del richiedere un prestito online?</h4>
                              <p>Te li spieghiamo noi!</p>
                              <DefaultButton
                                title="VAI AI VANTAGGI"
                                className="mt-3 btn-outline-light border-white"
                                url="/vantaggi/"
                              />
                            </div>
                            <div className="dropdown__cta-block__item">
                              <h4>Per ogni tua domanda, abbiamo la risposta che fa per te. Trovala subito nelle nostre FAQ!</h4>
                              <p>Leggi la nostra sezione dedicata alle FAQ</p>
                              <DefaultButton
                                title="Vai alle faq"
                                className="mt-3 btn-outline-light border-white"
                                url="/faq/"
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </li>
                  );
                }

                return null;
              })}

              {/* <li className="nav-item px-1 nav-item text-uppercase text-center">
                <Link className="nav-link py-3 px-2 px-xxl-4" to="/vantaggi/">
                  I vantaggi
                </Link>
              </li>
              <li className="nav-item px-1 nav-item text-uppercase text-center">
                <Link className="nav-link py-3 px-2 px-xxl-4" to="/come-funziona/">
                  Come funziona
                </Link>
              </li> */}
              <li className="nav-item px-1 nav-item--yellow text-uppercase text-center">
                <Link className="nav-link py-3 px-2 px-xxl-4" to="/blog/">
                  A Tutto Prestiti
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <MobileMenu pages={data.allWpPage.nodes} />
      </StyledNav>
    )}
  />
);

export default PrimaryMenu;
