import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

const StyledPrefooter = styled.div`
  background-color: #4f5050;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  color: #fff;
  padding: 3em 0;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 375px;
    @media (max-width: 480px) {
      flex: 50%;
      margin-bottom: 2em;
      padding-left: 1em;
    }
    @media (max-width: 1024px) {
      flex: 100%;
    }

    &__title {
      color: #e7c700;
    }

    &__text {
      font-weight: 500;
      font-size: 18px;
      .yellow {
        color: #e7c700;
        display: block;
      }
    }

    &__menu {
      list-style-type: none;
      line-height: 2em;
      padding-left: 0;
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    &__email {
      line-height: 1em;
    }

    &__socials {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;
    }
  }
`;

const Prefooter = (props) => (
  <StyledPrefooter>
    <div className="item pb-5 pb-lg-0">
      <StaticImage
        src="../assets/images/logo-prestiti-prefooter.png"
        className="img-fluid"
        alt="Separatore"
        placeholder="blurred"
        layout="fixed"
        width={219}
        height={88}
        objectFit="contain"
      />
      <div className="item__text">
        <span className="yellow fw-bold mt-2">Più veloce più risparmi</span>
      </div>
    </div>
    <div className="item">
      <h3 className="item__title mb-1 mb-lg-5">Chi siamo</h3>
      <ul className="item__menu">
        <li key={uuidv4()}>
          <Link to="/conosciamoci-meglio/">Conosciamoci meglio</Link>
        </li>
        <li key={uuidv4()}>
          <Link to="/manifesto/">
            Il nostro manifesto
          </Link>
        </li>
        <li key={uuidv4()}>
          <Link to="/lavora-con-noi/">Lavora con noi</Link>
        </li>
      </ul>
    </div>
    <div className="item">
      <h3 className="item__title mb-1 mb-lg-5">PrestitiFaiDaTe</h3>
      <ul className="item__menu">
        <li key={uuidv4()}>
          <Link to="/vantaggi/">I Vantaggi di PrestitiFaiDaTe</Link>
        </li>
        <li key={uuidv4()}>
          <Link to="/come-funziona/">Come funziona PrestitiFaiDaTe</Link>
        </li>
        <li key={uuidv4()}>
          <Link to="/convenzioni-nazionali-e-regionali/">Convenzioni regionali e nazionali</Link>
        </li>
      </ul>
    </div>
    <div className="item">
      <h3 className="item__title mb-1 mb-lg-5">Contattaci</h3>
      <ul className="item__menu">
        <li key={uuidv4()}>
          <Link to="/contattaci/">Compila il form di contatto</Link>
        </li>
        <li key={uuidv4()}>Servizio Clienti: <a href="tel:0699794910">06 99794910</a></li>
        <li className="item__email">
          Email:{" "}
          <a href="mailto:servizioclienti@prestitifaidate.it?subject=Nuovo%20contatto" style={{ color: "#e7c700" }}>
            servizioclienti@prestitifaidate.it
          </a>
        </li>
        <li className="item__socials">
          seguici su
          <a href="https://www.instagram.com/prestitifaidate/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../assets/images/icons/instagram.svg"
              className="img-fluid"
              alt="Instagram"
              placeholder="blurred"
              layout="fixed"
              width={18}
              height={18}
              objectFit="contain"
            />
          </a>
          <a href="https://www.facebook.com/prestitifaidate.it" target="_blank" rel="noreferrer">
            <StaticImage
              src="../assets/images/icons/facebook.svg"
              className="img-fluid"
              alt="Facebook"
              placeholder="blurred"
              layout="fixed"
              width={18}
              height={18}
              objectFit="contain"
            />
          </a>
          <a href="https://www.linkedin.com/company/prestitifaidate/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../assets/images/icons/linkedin.svg"
              className="img-fluid"
              alt="Linkedin"
              placeholder="blurred"
              layout="fixed"
              width={20}
              height={18}
              objectFit="contain"
            />
          </a>
        </li>
      </ul>
    </div>
  </StyledPrefooter>
);

export default Prefooter;
